import React from 'react';
import { Building2, TrendingUp } from 'lucide-react';

interface CompetitorAnalysisProps {
  competitors: number;
}

export default function CompetitorAnalysis({ competitors }: CompetitorAnalysisProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-4">
        <Building2 className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Competitor Analysis</h2>
      </div>

      <div className="space-y-4">
        <div>
          <p className="text-gray-600 mb-2">Competition Density</p>
          <div className="flex items-center gap-2">
            <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-blue-600 rounded-full"
                style={{ width: `${(competitors / 10) * 100}%` }}
              />
            </div>
            <span className="text-sm text-gray-600">
              {competitors}/10
            </span>
          </div>
        </div>

        <div className="border-t pt-4">
          <p className="text-gray-600 mb-2">Nearby Competitors</p>
          <div className="space-y-2">
            {[...Array(competitors)].map((_, i) => (
              <div
                key={i}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <span className="text-sm text-gray-600">
                  Competitor {i + 1}
                </span>
                <div className="flex items-center gap-2">
                  <TrendingUp className="w-4 h-4 text-green-500" />
                  <span className="text-sm text-gray-600">
                    {(Math.random() * 100000).toFixed(0)} gal/mo
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}