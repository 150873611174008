import React from 'react';
import { ArrowLeft, TrendingUp, Building2, DollarSign, ChevronRight } from 'lucide-react';
import { ValuationData } from './ValuationWizard';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

interface ValuationResultProps {
  data: ValuationData;
  onBack: () => void;
}

export default function ValuationResult({ data, onBack }: ValuationResultProps) {
  // Calculate estimated value based on various factors
  const calculateEstimatedValue = () => {
    const fuelRevenue = data.monthlyGallons * data.fuelMargin * 12;
    const annualStoreRevenue = data.storeRevenue * 12;
    const annualCarWashRevenue = (data.carWashRevenue || 0) * 12;
    const annualFoodServiceRevenue = (data.foodServiceRevenue || 0) * 12;
    
    const totalRevenue = fuelRevenue + annualStoreRevenue + annualCarWashRevenue + annualFoodServiceRevenue;
    const annualOperatingExpenses = data.operatingExpenses * 12;
    const netIncome = totalRevenue - annualOperatingExpenses;

    // Apply multipliers based on various factors
    let multiplier = 3.5; // Base multiplier

    // Adjust multiplier based on location type
    if (data.propertyType === 'highway') multiplier += 0.5;
    if (data.propertyType === 'urban') multiplier += 0.3;

    // Adjust for brand affiliation
    if (data.brandAffiliation === 'branded') multiplier += 0.2;

    // Adjust for additional profit centers
    if (data.hasCarWash) multiplier += 0.3;
    if (data.hasFoodService) multiplier += 0.2;

    const estimatedValue = netIncome * multiplier;
    const range = 0.1; // 10% range

    return {
      low: Math.round(estimatedValue * (1 - range)),
      high: Math.round(estimatedValue * (1 + range)),
      confidence: 85
    };
  };

  const estimatedValue = calculateEstimatedValue();

  const metrics = [
    {
      label: 'Monthly Fuel Revenue',
      value: `$${(data.monthlyGallons * data.fuelMargin).toLocaleString()}`,
      icon: <TrendingUp className="w-6 h-6" />
    },
    {
      label: 'Store Revenue',
      value: `$${data.storeRevenue.toLocaleString()}`,
      icon: <Building2 className="w-6 h-6" />
    },
    {
      label: 'Operating Expenses',
      value: `$${data.operatingExpenses.toLocaleString()}`,
      icon: <DollarSign className="w-6 h-6" />
    }
  ];

  return (
    <div className="space-y-8">
      <Button
        variant="outline"
        onClick={onBack}
        className="mb-6"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Calculator
      </Button>

      <div className="text-center mb-12">
        <h2 className="text-2xl font-bold mb-2">Estimated Property Value</h2>
        <p className="text-4xl font-bold text-blue-600">
          ${(estimatedValue.low / 1000000).toFixed(1)}M - ${(estimatedValue.high / 1000000).toFixed(1)}M
        </p>
        <p className="text-gray-600 mt-2">
          {estimatedValue.confidence}% Confidence Range
        </p>
      </div>

      <div className="grid md:grid-cols-3 gap-6 mb-8">
        {metrics.map((metric) => (
          <Card key={metric.label}>
            <Card.Content className="p-6">
              <div className="flex items-center gap-2 text-blue-600 mb-2">
                {metric.icon}
                <span className="font-semibold">{metric.label}</span>
              </div>
              <p className="text-2xl font-bold">{metric.value}</p>
            </Card.Content>
          </Card>
        ))}
      </div>

      <Card>
        <Card.Header>
          <Card.Title>Valuation Insights</Card.Title>
          <Card.Description>
            Key factors influencing your property's value
          </Card.Description>
        </Card.Header>
        <Card.Content>
          <div className="space-y-6">
            <div>
              <h3 className="font-semibold mb-2">Location & Property</h3>
              <ul className="space-y-2 text-gray-600">
                <li className="flex items-center gap-2">
                  <ChevronRight className="w-4 h-4 text-blue-600" />
                  {data.propertyType === 'highway' ? 'Prime highway location with high traffic potential' :
                   data.propertyType === 'urban' ? 'Strategic urban location with dense population' :
                   'Well-positioned property with steady traffic'}
                </li>
                <li className="flex items-center gap-2">
                  <ChevronRight className="w-4 h-4 text-blue-600" />
                  {data.numberOfPumps} fuel pumps providing excellent service capacity
                </li>
                {data.trafficCount && (
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-blue-600" />
                    High daily traffic count of {data.trafficCount.toLocaleString()} vehicles
                  </li>
                )}
              </ul>
            </div>

            <div>
              <h3 className="font-semibold mb-2">Revenue Streams</h3>
              <ul className="space-y-2 text-gray-600">
                {data.hasCarWash && (
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-blue-600" />
                    Additional revenue from {data.carWashType} car wash facility
                  </li>
                )}
                {data.hasFoodService && (
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-blue-600" />
                    Food service operations contributing to property value
                  </li>
                )}
                {data.additionalServices.length > 0 && (
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-blue-600" />
                    Multiple additional services enhancing revenue potential
                  </li>
                )}
              </ul>
            </div>

            <div>
              <h3 className="font-semibold mb-2">Market Position</h3>
              <ul className="space-y-2 text-gray-600">
                {data.brandAffiliation === 'branded' && (
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-blue-600" />
                    Strong brand affiliation with {data.brandName}
                  </li>
                )}
                <li className="flex items-center gap-2">
                  <ChevronRight className="w-4 h-4 text-blue-600" />
                  Competitive fuel margins and store performance
                </li>
              </ul>
            </div>
          </div>
        </Card.Content>
      </Card>

      <div className="flex justify-center mt-8">
        <Button>
          Download Detailed Report
          <ChevronRight className="w-4 h-4 ml-2" />
        </Button>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-bold text-gray-800 mb-4">Next Steps</h3>
        <p className="text-gray-600 mb-4">
          If you're interested in selling your property or exploring financial options, we can connect you with trusted local professionals.
        </p>
        <div className="flex gap-4">
          <Button
            variant="ghost"
            onClick={() => {
              // Navigate to broker connection page
            }}
            className="text-blue-600 hover:underline"
          >
            Connect with a Local Broker
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              // Navigate to financial institution connection page
            }}
            className="text-blue-600 hover:underline"
          >
            Explore Financing Options
          </Button>
        </div>
      </div>
    </div>
  );
}