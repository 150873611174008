import React from 'react';
import { MapPin, Navigation } from 'lucide-react';
import { Card } from '../ui/Card';

interface Competitor {
  id: string;
  name: string;
  distance: number;
  monthlyVolume: number;
  lat: number;
  lng: number;
}

export default function CompetitorMap() {
  const competitors: Competitor[] = [
    {
      id: '1',
      name: 'City Gas Station',
      distance: 0.8,
      monthlyVolume: 120000,
      lat: 34.0522,
      lng: -118.2437
    },
    {
      id: '2',
      name: 'Highway Fuel Stop',
      distance: 1.2,
      monthlyVolume: 180000,
      lat: 34.0522,
      lng: -118.2437
    },
    {
      id: '3',
      name: 'Corner Gas & Market',
      distance: 1.5,
      monthlyVolume: 90000,
      lat: 34.0522,
      lng: -118.2437
    }
  ];

  return (
    <Card>
      <Card.Header>
        <Card.Title>Competitor Analysis</Card.Title>
        <Card.Description>
          Nearby competitors within 2-mile radius
        </Card.Description>
      </Card.Header>
      <Card.Content>
        <div className="relative w-full h-[400px] bg-gray-100 rounded-lg mb-6">
          {/* Map placeholder - would be replaced with actual map implementation */}
          <div className="absolute inset-0 flex items-center justify-center">
            <Navigation className="w-8 h-8 text-blue-600" />
          </div>
        </div>

        <div className="space-y-4">
          {competitors.map((competitor) => (
            <div
              key={competitor.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                <MapPin className="w-5 h-5 text-blue-600" />
                <div>
                  <h4 className="font-medium">{competitor.name}</h4>
                  <p className="text-sm text-gray-600">
                    {competitor.distance} miles away
                  </p>
                </div>
              </div>
              <div className="text-right">
                <p className="font-medium">
                  {(competitor.monthlyVolume / 1000).toFixed(0)}K
                </p>
                <p className="text-sm text-gray-600">gal/month</p>
              </div>
            </div>
          ))}
        </div>
      </Card.Content>
    </Card>
  );
}