import React from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData } from '../ValuationWizard';
import { Tooltip } from '../../ui/Tooltip';

interface BasicInfoProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
}

const fuelBrands = [
  'Chevron',
  'Shell',
  'Arco',
  'Sinclair',
  'BP',
  'Exxon',
  'Mobil',
  'Texaco',
  'Valero',
  'Other'
];

export default function BasicInfo({ data, onUpdate }: BasicInfoProps) {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Property Name
          </label>
          <Tooltip content="Enter the business name or property identifier">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="text"
          value={data.propertyName}
          onChange={(e) => onUpdate({ propertyName: e.target.value })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., Main Street Gas & Service"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <Tooltip content="Enter the full address of the property">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="text"
          value={data.location}
          onChange={(e) => onUpdate({ location: e.target.value })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Full address"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Property Ownership
          </label>
          <Tooltip content="Select whether the property is owned or leased">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.ownership}
          onChange={(e) => onUpdate({ ownership: e.target.value as 'owned' | 'leased' })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="owned">Owned</option>
          <option value="leased">Leased</option>
        </select>

        {data.ownership === 'leased' && (
          <div className="mt-4">
            <div className="flex items-center gap-2 mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Lease Term Remaining (Years)
              </label>
              <Tooltip content="Enter the number of years remaining on the current lease">
                <HelpCircle className="w-4 h-4 text-gray-400" />
              </Tooltip>
            </div>
            <input
              type="number"
              value={data.leaseTermYears || ''}
              onChange={(e) => {
                const value = e.target.value;
                const formattedValue = value ? parseInt(value.replace(/,/g, ''), 10) : '';
                onUpdate({ leaseTermYears: formattedValue as number });
              }}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Years remaining"
            />
          </div>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Brand Affiliation
          </label>
          <Tooltip content="Select whether the station is branded or unbranded">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.brandAffiliation}
          onChange={(e) => onUpdate({ brandAffiliation: e.target.value as 'branded' | 'unbranded' })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="branded">Branded</option>
          <option value="unbranded">Unbranded</option>
        </select>

        {data.brandAffiliation === 'branded' && (
          <>
            <div className="mt-4">
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Brand Name
                </label>
                <Tooltip content="Select the fuel brand affiliated with this station">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <select
                value={data.brandName}
                onChange={(e) => onUpdate({ brandName: e.target.value })}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select brand</option>
                {fuelBrands.map((brand) => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Fuel Supply Agreement Years Remaining
                </label>
                <Tooltip content="Enter the number of years remaining on the fuel supply agreement">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <input
                type="number"
                value={data.fuelSupplyYears || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  const formattedValue = value ? parseInt(value.replace(/,/g, ''), 10) : '';
                  onUpdate({ fuelSupplyYears: formattedValue as number });
                }}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Years remaining"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}