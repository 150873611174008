import React from 'react';
import { Calendar } from 'lucide-react';

export default function NewsGrid() {
  const articles = [
    {
      title: "California's New Environmental Regulations for Gas Stations",
      excerpt: "Learn about the latest environmental compliance requirements affecting gas station operators in California...",
      date: "Mar 15, 2024",
      image: "https://images.unsplash.com/photo-1553729459-efe14ef6055d?auto=format&fit=crop&q=80"
    },
    {
      title: "EV Charging: The Next Revenue Stream",
      excerpt: "Discover how gas station owners are capitalizing on the growing electric vehicle market...",
      date: "Mar 12, 2024",
      image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80"
    },
    {
      title: "Maximizing Convenience Store Profits",
      excerpt: "Expert tips on optimizing your convenience store layout and product mix for higher margins...",
      date: "Mar 10, 2024",
      image: "https://images.unsplash.com/photo-1444653614773-995cb1ef9efa?auto=format&fit=crop&q=80"
    }
  ];

  return (
    <div className="grid md:grid-cols-3 gap-8">
      {articles.map((article) => (
        <article
          key={article.title}
          className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
        >
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-2 line-clamp-2">
              {article.title}
            </h3>
            <p className="text-gray-600 mb-4 line-clamp-2">
              {article.excerpt}
            </p>
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <Calendar className="w-4 h-4" />
              {article.date}
            </div>
          </div>
        </article>
      ))}
    </div>
  );
}