import React from 'react';
import { Calendar, ArrowRight } from 'lucide-react';

interface ArticleGridProps {
  filters: {
    category: string;
    tags: string[];
    searchQuery: string;
  };
}

export default function ArticleGrid({ filters }: ArticleGridProps) {
  const articles = [
    {
      id: '1',
      title: "California's New Environmental Regulations for Gas Stations",
      excerpt: "Learn about the latest environmental compliance requirements affecting gas station operators in California...",
      date: "Mar 15, 2024",
      category: "Regulations",
      tags: ["Compliance", "Environmental"],
      image: "https://images.unsplash.com/photo-1553729459-efe14ef6055d?auto=format&fit=crop&q=80",
      readTime: "5 min read"
    },
    {
      id: '2',
      title: "EV Charging: The Next Revenue Stream",
      excerpt: "Discover how gas station owners are capitalizing on the growing electric vehicle market...",
      date: "Mar 12, 2024",
      category: "Technology",
      tags: ["EV Charging", "Market Trends"],
      image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80",
      readTime: "8 min read"
    },
    {
      id: '3',
      title: "Maximizing Convenience Store Profits",
      excerpt: "Expert tips on optimizing your convenience store layout and product mix for higher margins...",
      date: "Mar 10, 2024",
      category: "Operations",
      tags: ["Convenience Store", "Market Trends"],
      image: "https://images.unsplash.com/photo-1444653614773-995cb1ef9efa?auto=format&fit=crop&q=80",
      readTime: "6 min read"
    }
  ];

  const filteredArticles = articles.filter((article) => {
    if (filters.category && article.category !== filters.category) return false;
    if (filters.tags.length > 0 && !article.tags.some(tag => filters.tags.includes(tag))) return false;
    if (filters.searchQuery && !article.title.toLowerCase().includes(filters.searchQuery.toLowerCase())) return false;
    return true;
  });

  if (filteredArticles.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">No articles found matching your criteria.</p>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 gap-6">
      {filteredArticles.map((article) => (
        <article
          key={article.id}
          className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
        >
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-48 object-cover"
          />
          <div className="p-6">
            <div className="flex items-center gap-2 mb-2">
              <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-sm">
                {article.category}
              </span>
              <span className="text-sm text-gray-500">
                {article.readTime}
              </span>
            </div>
            
            <h3 className="text-xl font-semibold mb-2 line-clamp-2">
              {article.title}
            </h3>
            
            <p className="text-gray-600 mb-4 line-clamp-2">
              {article.excerpt}
            </p>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Calendar className="w-4 h-4" />
                {article.date}
              </div>
              
              <button className="text-blue-600 font-semibold flex items-center gap-1 hover:text-blue-700">
                Read More
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
}