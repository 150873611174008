import React from 'react';
import { Star, MapPin, Fuel } from 'lucide-react';

export default function SavedProperties() {
  const savedProperties = [
    {
      id: '1',
      title: 'Modern Gas Station with Car Wash',
      location: 'Los Angeles, CA',
      monthlyGallons: 150000,
      price: 2500000,
      image: 'https://images.unsplash.com/photo-1542338106-1630c81a8cce?auto=format&fit=crop&q=80'
    },
    {
      id: '2',
      title: 'Highway Gas Station Complex',
      location: 'Sacramento, CA',
      monthlyGallons: 200000,
      price: 3500000,
      image: 'https://images.unsplash.com/photo-1613093327691-19b5a41595e0?auto=format&fit=crop&q=80'
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Star className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Saved Properties</h2>
      </div>

      <div className="space-y-4">
        {savedProperties.map((property) => (
          <div
            key={property.id}
            className="flex gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <img
              src={property.image}
              alt={property.title}
              className="w-24 h-24 object-cover rounded-lg"
            />
            <div className="flex-1">
              <h3 className="font-semibold mb-2">{property.title}</h3>
              <div className="space-y-1 text-sm text-gray-600">
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4" />
                  {property.location}
                </div>
                <div className="flex items-center gap-2">
                  <Fuel className="w-4 h-4" />
                  {(property.monthlyGallons / 1000).toFixed(0)}K gal/month
                </div>
              </div>
            </div>
            <div className="text-right">
              <p className="font-bold text-blue-600">
                ${(property.price / 1000000).toFixed(1)}M
              </p>
              <button className="text-sm text-blue-600 hover:text-blue-700 mt-2">
                View Details
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}