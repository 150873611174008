import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import BasicInfo from './steps/BasicInfo';
import OperationalMetrics from './steps/OperationalMetrics';
import PropertyDetails from './steps/PropertyDetails';
import ProfitCenters from './steps/ProfitCenters';
import FinancialMetrics from './steps/FinancialMetrics';
import ValuationResult from './ValuationResult';
import { Button } from '../ui/Button';
import { Tooltip } from '../ui/Tooltip';

const steps = [
  { id: 'basic', title: 'Basic Information' },
  { id: 'operational', title: 'Operational Metrics' },
  { id: 'property', title: 'Property Details' },
  { id: 'profit', title: 'Profit Centers' },
  { id: 'financial', title: 'Financial Metrics' }
];

export interface ValuationData {
  // Basic Information
  propertyName: string;
  location: string;
  propertyType: string;
  ownership: 'owned' | 'leased';
  leaseTermYears?: number;
  brandAffiliation: 'branded' | 'unbranded';
  brandName?: string;
  fuelSupplyYears?: number;

  // Operational Metrics
  tankCapacity: number;
  operatingHours: '24/7' | 'specific' | 'not-applicable';
  specificHours?: string;
  numberOfPumps: number;
  fuelTypes: string[];

  // Property Details
  propertySize: number;
  lotSize: number;
  yearBuilt: number;
  lastRenovated?: number;
  zoningType: string;
  trafficCount?: number;

  // Profit Centers
  hasCarWash: boolean;
  carWashType?: 'automatic' | 'self-service';
  carWashRevenue?: number;
  hasFoodService: boolean;
  foodServiceType?: string[];
  foodServiceRevenue?: number;
  foodServiceOwnership?: 'owned' | 'leased';
  foodServiceLeaseYears?: number;
  foodServiceRent?: number;
  additionalServices: string[];

  // Financial Metrics
  monthlyGallons: number;
  fuelMargin: number;
  storeRevenue: number;
  operatingExpenses: number;
  salaries?: number;
  utilities?: number;
  propertyTax?: number;
  isAnnual?: boolean;
}

export default function ValuationWizard() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<ValuationData>({
    propertyName: '',
    location: '',
    propertyType: '',
    ownership: 'owned',
    brandAffiliation: 'unbranded',
    tankCapacity: 0,
    operatingHours: '24/7',
    numberOfPumps: 0,
    fuelTypes: [],
    propertySize: 0,
    lotSize: 0,
    yearBuilt: 0,
    zoningType: '',
    hasCarWash: false,
    hasFoodService: false,
    additionalServices: [],
    monthlyGallons: 0,
    fuelMargin: 0,
    storeRevenue: 0,
    operatingExpenses: 0,
    isAnnual: false
  });

  const [showResults, setShowResults] = useState(false);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowResults(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleUpdateData = (data: Partial<ValuationData>) => {
    setFormData({ ...formData, ...data });
  };

  const renderStep = () => {
    switch (steps[currentStep].id) {
      case 'basic':
        return <BasicInfo data={formData} onUpdate={handleUpdateData} />;
      case 'operational':
        return <OperationalMetrics data={formData} onUpdate={handleUpdateData} />;
      case 'property':
        return <PropertyDetails data={formData} onUpdate={handleUpdateData} />;
      case 'profit':
        return <ProfitCenters data={formData} onUpdate={handleUpdateData} />;
      case 'financial':
        return <FinancialMetrics data={formData} onUpdate={handleUpdateData} />;
      default:
        return null;
    }
  };

  if (showResults) {
    return <ValuationResult data={formData} onBack={() => setShowResults(false)} />;
  }

  return (
    <div className="max-w-3xl mx-auto">
      {/* Progress Bar */}
      <div className="mb-8">
        <div className="flex items-center justify-between mb-4">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className="flex items-center"
            >
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  index <= currentStep
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-600'
                }`}
              >
                {index + 1}
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`w-full h-1 ${
                    index < currentStep ? 'bg-blue-600' : 'bg-gray-200'
                  }`}
                />
              )}
            </div>
          ))}
        </div>
        <p className="text-center text-gray-600">
          Step {currentStep + 1}: {steps[currentStep].title}
        </p>
      </div>

      {/* Form Content */}
      <div className="bg-white rounded-xl shadow-lg p-8">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            {renderStep()}
          </motion.div>
        </AnimatePresence>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-8 pt-6 border-t">
          <Button
            variant="outline"
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          <Button onClick={handleNext}>
            {currentStep === steps.length - 1 ? 'Calculate Value' : 'Next'}
            <ChevronRight className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
}