import React from 'react';
import { MapPin } from 'lucide-react';

interface LocationMapProps {
  selectedLocation: {
    lat: number;
    lng: number;
    address: string;
  };
  onLocationChange: (location: any) => void;
  radius: number;
}

export default function LocationMap({ selectedLocation, radius }: LocationMapProps) {
  return (
    <div className="relative w-full h-[400px] bg-gray-100 rounded-lg overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <MapPin className="w-8 h-8 text-blue-600 mx-auto mb-2" />
          <p className="text-gray-600">
            Map integration will display here with markers for:
          </p>
          <ul className="text-sm text-gray-500 mt-2">
            <li>• Selected location</li>
            <li>• Competitor locations</li>
            <li>• Fuel terminals</li>
            <li>• Traffic data points</li>
          </ul>
          <p className="text-sm text-gray-500 mt-2">
            Analysis radius: {radius} miles
          </p>
        </div>
      </div>
    </div>
  );
}