import React from 'react';
import { Users, DollarSign, TrendingUp } from 'lucide-react';

interface DemographicDataProps {
  population: number;
  medianIncome: number;
}

export default function DemographicData({ population, medianIncome }: DemographicDataProps) {
  const demographics = [
    {
      label: 'Age Distribution',
      data: [
        { label: '18-24', value: 15 },
        { label: '25-34', value: 25 },
        { label: '35-44', value: 20 },
        { label: '45-54', value: 18 },
        { label: '55+', value: 22 }
      ]
    },
    {
      label: 'Income Levels',
      data: [
        { label: '<$50K', value: 20 },
        { label: '$50K-$75K', value: 30 },
        { label: '$75K-$100K', value: 25 },
        { label: '$100K+', value: 25 }
      ]
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center gap-2 mb-6">
        <Users className="w-6 h-6 text-blue-600" />
        <h2 className="text-xl font-semibold">Demographics</h2>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
          <div>
            <p className="text-sm text-gray-600">Total Population</p>
            <p className="text-xl font-bold">
              {(population / 1000).toFixed(1)}K
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Median Income</p>
            <p className="text-xl font-bold">
              ${(medianIncome / 1000).toFixed(0)}K
            </p>
          </div>
        </div>

        {demographics.map((section) => (
          <div key={section.label}>
            <p className="text-gray-600 mb-2">{section.label}</p>
            <div className="space-y-2">
              {section.data.map((item) => (
                <div key={item.label} className="space-y-1">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-600">{item.label}</span>
                    <span className="text-gray-600">{item.value}%</span>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-600 rounded-full"
                      style={{ width: `${item.value}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="border-t pt-4">
          <p className="text-sm text-gray-600 mb-2">Growth Potential</p>
          <div className="flex items-center gap-2 text-green-500">
            <TrendingUp className="w-4 h-4" />
            <span>High growth area</span>
          </div>
        </div>
      </div>
    </div>
  );
}