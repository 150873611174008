import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { Card } from '../ui/Card';
import { Tabs } from '../ui/Tabs';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const timeRanges = [
  { id: '7d', label: '7 Days' },
  { id: '1m', label: '1 Month' },
  { id: '3m', label: '3 Months' },
  { id: '1y', label: '1 Year' }
];

export default function MarketTrends() {
  const [activeRange, setActiveRange] = useState('1m');

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Property Values',
        data: [2.1, 2.3, 2.2, 2.5, 2.4, 2.6],
        borderColor: 'rgb(37, 99, 235)',
        backgroundColor: 'rgba(37, 99, 235, 0.1)',
        fill: true,
        tension: 0.4
      },
      {
        label: 'Fuel Volume',
        data: [120, 135, 125, 140, 138, 145],
        borderColor: 'rgb(16, 185, 129)',
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          padding: 20
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
        grid: {
          display: true
        },
        ticks: {
          callback: function(value) {
            return `$${value}M`;
          }
        }
      },
      x: {
        type: 'category',
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <div className="flex items-center justify-between">
          <Card.Title>Market Trends</Card.Title>
          <Tabs
            tabs={timeRanges}
            activeTab={activeRange}
            onChange={setActiveRange}
          />
        </div>
      </Card.Header>
      <Card.Content>
        <div className="h-[400px]">
          <Line data={data} options={options} />
        </div>
      </Card.Content>
    </Card>
  );
}