import React from 'react';

interface ServiceCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
}

export default function ServiceCard({ title, description, icon, onClick }: ServiceCardProps) {
  return (
    <button
      onClick={onClick}
      className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow text-left w-full group"
    >
      <div className="text-blue-600 mb-4 group-hover:scale-110 transition-transform">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors">
        {title}
      </h3>
      <p className="text-gray-600">
        {description}
      </p>
    </button>
  );
}