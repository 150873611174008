import { Fragment } from 'react';
import { Check, Minus } from 'lucide-react';
import { Vendor } from '../../pages/VendorList';
import { Modal } from '../ui/Modal';

interface VendorComparisonProps {
  vendors: Vendor[];
  onClose: () => void;
}

export default function VendorComparison({ vendors, onClose }: VendorComparisonProps) {
  const comparisonCategories = [
    { label: 'Basic Information', items: [
      { key: 'rating', label: 'Rating' },
      { key: 'reviewCount', label: 'Number of Reviews' },
      { key: 'verified', label: 'Verified Status' },
      { key: 'location', label: 'Location' }
    ]},
    { label: 'Services & Coverage', items: [
      { key: 'services', label: 'Available Services', type: 'array' },
      { key: 'serviceArea', label: 'Service Areas', type: 'array' }
    ]},
    { label: 'Business Details', items: [
      { key: 'pricing', label: 'Pricing Level' },
      { key: 'responseTime', label: 'Response Time' },
      { key: 'certifications', label: 'Certifications', type: 'array' }
    ]}
  ];

  const renderValue = (vendor: Vendor, key: keyof Vendor, type?: string) => {
    const value = vendor[key];
    
    if (type === 'array' && Array.isArray(value)) {
      return (
        <ul className="list-disc list-inside">
          {value.map((item, index) => (
            <li key={index} className="text-sm">{String(item)}</li>
          ))}
        </ul>
      );
    }

    if (typeof value === 'boolean') {
      return value ? (
        <Check className="w-5 h-5 text-green-600" />
      ) : (
        <Minus className="w-5 h-5 text-gray-400" />
      );
    }

    return String(value);
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Vendor Comparison"
    >
      <div className="max-h-[70vh] overflow-y-auto">
        <table className="w-full">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              <th className="p-4 text-left text-gray-600 font-medium">
                Features
              </th>
              {vendors.map((vendor) => (
                <th key={vendor.id} className="p-4 text-left text-gray-600 font-medium">
                  {vendor.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {comparisonCategories.map((category) => (
              <Fragment key={category.label}>
                <tr className="bg-gray-50">
                  <td
                    colSpan={vendors.length + 1}
                    className="p-4 font-semibold text-gray-700"
                  >
                    {category.label}
                  </td>
                </tr>
                {category.items.map((item) => (
                  <tr key={item.key} className="hover:bg-gray-50">
                    <td className="p-4 text-gray-600">
                      {item.label}
                    </td>
                    {vendors.map((vendor) => (
                      <td key={vendor.id} className="p-4">
                        {renderValue(vendor, item.key as keyof Vendor, item.type)}
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}