import React, { useState } from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData } from '../ValuationWizard';
import { Tooltip } from '../../ui/Tooltip';

interface FinancialMetricsProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
}

export default function FinancialMetrics({ data, onUpdate }: FinancialMetricsProps) {
  const [isAnnual, setIsAnnual] = useState(false);

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isAnnualSelected = e.target.value === 'annual';
    setIsAnnual(isAnnualSelected);
    
    // Convert existing values when switching periods
    if (isAnnualSelected) {
      onUpdate({
        storeRevenue: (data.storeRevenue || 0) * 12,
        operatingExpenses: (data.operatingExpenses || 0) * 12,
        salaries: (data.salaries || 0) * 12,
        utilities: (data.utilities || 0) * 12
      });
    } else {
      onUpdate({
        storeRevenue: Math.round((data.storeRevenue || 0) / 12),
        operatingExpenses: Math.round((data.operatingExpenses || 0) / 12),
        salaries: Math.round((data.salaries || 0) / 12),
        utilities: Math.round((data.utilities || 0) / 12)
      });
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Reporting Period
          </label>
          <select
            value={isAnnual ? 'annual' : 'monthly'}
            onChange={handlePeriodChange}
            className="ml-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="monthly">Monthly</option>
            <option value="annual">Annual</option>
          </select>
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Fuel Volume (Gallons)
          </label>
          <Tooltip content={`Average ${isAnnual ? 'annual' : 'monthly'} fuel sales volume in gallons`}>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="text"
          value={data.monthlyGallons ? data.monthlyGallons.toLocaleString() : ''}
          onChange={(e) => {
            const value = e.target.value;
            const numericValue = parseInt(value.replace(/,/g, ''), 10);
            onUpdate({ monthlyGallons: isNaN(numericValue) ? 0 : numericValue });
          }}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder={`e.g., ${isAnnual ? '1,200,000' : '100,000'}`}
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Fuel Margin (per gallon)
          </label>
          <Tooltip content="Average profit margin per gallon of fuel">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="number"
            step="0.01"
            value={data.fuelMargin || ''}
            onChange={(e) => onUpdate({ fuelMargin: Number(e.target.value) })}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="0.25"
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Store Revenue
          </label>
          <Tooltip content={`Average ${isAnnual ? 'annual' : 'monthly'} revenue from convenience store sales`}>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="text"
            value={data.storeRevenue ? data.storeRevenue.toLocaleString() : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = parseInt(value.replace(/,/g, ''), 10);
              onUpdate({ storeRevenue: isNaN(numericValue) ? 0 : numericValue });
            }}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={`e.g., ${isAnnual ? '600,000' : '50,000'}`}
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Operating Expenses
          </label>
          <Tooltip content={`Total ${isAnnual ? 'annual' : 'monthly'} operating expenses excluding fuel costs`}>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="text"
            value={data.operatingExpenses ? data.operatingExpenses.toLocaleString() : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = parseInt(value.replace(/,/g, ''), 10);
              onUpdate({ operatingExpenses: isNaN(numericValue) ? 0 : numericValue });
            }}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={`e.g., ${isAnnual ? '360,000' : '30,000'}`}
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Salaries/Wages
          </label>
          <Tooltip content={`Total ${isAnnual ? 'annual' : 'monthly'} salaries and wages expenses`}>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="text"
            value={data.salaries ? data.salaries.toLocaleString() : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = parseInt(value.replace(/,/g, ''), 10);
              onUpdate({ salaries: isNaN(numericValue) ? 0 : numericValue });
            }}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={`e.g., ${isAnnual ? '240,000' : '20,000'}`}
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Utilities
          </label>
          <Tooltip content={`Total ${isAnnual ? 'annual' : 'monthly'} utilities expenses`}>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="text"
            value={data.utilities ? data.utilities.toLocaleString() : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = parseInt(value.replace(/,/g, ''), 10);
              onUpdate({ utilities: isNaN(numericValue) ? 0 : numericValue });
            }}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder={`e.g., ${isAnnual ? '48,000' : '4,000'}`}
          />
        </div>
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Annual Property Tax
          </label>
          <Tooltip content="Annual property tax amount (if applicable)">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="relative">
          <span className="absolute left-3 top-3 text-gray-500">$</span>
          <input
            type="text"
            value={data.propertyTax ? data.propertyTax.toLocaleString() : ''}
            onChange={(e) => {
              const value = e.target.value;
              const numericValue = parseInt(value.replace(/,/g, ''), 10);
              onUpdate({ propertyTax: isNaN(numericValue) ? 0 : numericValue });
            }}
            className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Leave blank if unknown"
          />
        </div>
      </div>
    </div>
  );
}