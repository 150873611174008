import React from 'react';
import VendorCard from './VendorCard';
import VendorComparison from './VendorComparison';
import { Vendor } from '../../pages/VendorList';
import { useToast } from '../../hooks/useToast';
import { Button } from '../ui/Button';
import { X } from 'lucide-react';

interface VendorGridProps {
  filters: {
    category: string;
    search: string;
    rating: number;
    verified: boolean;
    serviceArea: string;
    services: string[];
  };
}

export default function VendorGrid({ filters }: VendorGridProps) {
  const { showToast } = useToast();
  const [favorites, setFavorites] = React.useState<string[]>([]);
  const [selectedVendors, setSelectedVendors] = React.useState<string[]>([]);
  const [showComparison, setShowComparison] = React.useState(false);

  const vendors: Vendor[] = [
    // Fuel Supply Vendors
    {
      id: '1',
      name: 'Premium Fuel Supply Co.',
      category: 'fuel',
      description: 'Leading fuel supplier with 24/7 delivery service and competitive pricing.',
      rating: 4.8,
      reviewCount: 156,
      location: 'Los Angeles, CA',
      serviceArea: ['Los Angeles', 'Orange County', 'Riverside'],
      verified: true,
      services: ['Fuel Delivery', '24/7 Service', 'Emergency Supply'],
      contact: {
        phone: '(555) 123-4567',
        email: 'contact@premiumfuel.com',
        website: 'www.premiumfuel.com'
      },
      image: 'https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20gas%20company%20pic.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZ2FzIGNvbXBhbnkgcGljLmpmaWYiLCJpYXQiOjE3MzE2Mzg0MjAsImV4cCI6MTc2MzE3NDQyMH0.53u1qzzfMjF2BjPbxboFxKNPt8iVI4CIXVir3gvPD_c&t=2024-11-15T02%3A40%3A20.349Z',
      pricing: 'Competitive',
      responseTime: '< 2 hours',
      certifications: ['ISO 9001', 'EPA Certified']
    },

    // Equipment Vendors
    {
      id: '2',
      name: 'TechPump Solutions',
      category: 'equipment',
      description: 'Expert pump maintenance and repair services with certified technicians.',
      rating: 4.6,
      reviewCount: 98,
      location: 'San Francisco, CA',
      serviceArea: ['San Francisco', 'San Jose', 'Oakland'],
      verified: true,
      services: ['Pump Repair', 'Maintenance', 'Installation'],
      contact: {
        phone: '(555) 234-5678',
        email: 'service@techpump.com',
        website: 'www.techpump.com'
      },
      image: 'https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20fuel%20pump%20company%20pic.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZnVlbCBwdW1wIGNvbXBhbnkgcGljLmpmaWYiLCJpYXQiOjE3MzE2MzgyODAsImV4cCI6MTc2MzE3NDI4MH0.1rTYsvf_S_2lxNDWCiUxgyFtzxod2a-_U_WarRoIv3U&t=2024-11-15T02%3A38%3A00.936Z',
      pricing: 'Premium',
      responseTime: '< 1 hour',
      certifications: ['ASE Certified', 'PEI']
    },

    // Financial Services - Banks
    {
      id: '3',
      name: 'PetroBank Financial',
      category: 'financial',
      description: 'Specialized banking solutions for gas station and convenience store operators.',
      rating: 4.9,
      reviewCount: 245,
      location: 'New York, NY',
      serviceArea: ['Nationwide'],
      verified: true,
      services: ['Business Loans', 'Equipment Financing', 'SBA Loans', 'Real Estate Financing'],
      contact: {
        phone: '(555) 789-0123',
        email: 'lending@petrobank.com',
        website: 'www.petrobank.com'
      },
      image: 'https://images.unsplash.com/photo-1560472354-b33ff0c44a43?auto=format&fit=crop&q=80',
      pricing: 'Custom',
      responseTime: '24-48 hours',
      certifications: ['FDIC', 'SBA Preferred Lender']
    },
    {
      id: '4',
      name: 'Energy Capital Partners',
      category: 'financial',
      description: 'Private equity firm specializing in petroleum retail investments.',
      rating: 4.7,
      reviewCount: 167,
      location: 'Chicago, IL',
      serviceArea: ['Nationwide'],
      verified: true,
      services: ['Private Equity', 'Growth Capital', 'Acquisition Financing', 'Restructuring'],
      contact: {
        phone: '(555) 901-2345',
        email: 'invest@energycapital.com',
        website: 'www.energycapital.com'
      },
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80',
      pricing: 'Custom',
      responseTime: '48 hours',
      certifications: ['SEC Registered', 'FINRA Member']
    },
    {
      id: '5',
      name: 'Fuel Industry Lending',
      category: 'financial',
      description: 'Specialized debt financing solutions for gas station operators.',
      rating: 4.8,
      reviewCount: 189,
      location: 'Houston, TX',
      serviceArea: ['Nationwide'],
      verified: true,
      services: ['Working Capital', 'Inventory Financing', 'Equipment Loans', 'Bridge Loans'],
      contact: {
        phone: '(555) 345-6789',
        email: 'loans@fuelindustry.com',
        website: 'www.fuelindustry.com'
      },
      image: 'https://images.unsplash.com/photo-1579532537598-459ecdaf39cc?auto=format&fit=crop&q=80',
      pricing: 'Competitive',
      responseTime: '24 hours',
      certifications: ['NEFA Member', 'ELFA Member']
    },

    // Legal & Compliance Services
    {
      id: '6',
      name: 'PetroLegal Solutions',
      category: 'compliance',
      description: 'Full-service law firm specializing in petroleum retail legal matters.',
      rating: 4.9,
      reviewCount: 213,
      location: 'Washington, DC',
      serviceArea: ['Nationwide'],
      verified: true,
      services: ['Contract Review', 'Environmental Compliance', 'Real Estate Law', 'Business Formation'],
      contact: {
        phone: '(555) 456-7890',
        email: 'info@petrolegal.com',
        website: 'www.petrolegal.com'
      },
      image: 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80',
      pricing: 'Premium',
      responseTime: '24 hours',
      certifications: ['State Bar Certified', 'ABA Member']
    },
    {
      id: '7',
      name: 'EcoCompliance Experts',
      category: 'compliance',
      description: 'Environmental compliance and certification specialists.',
      rating: 4.8,
      reviewCount: 178,
      location: 'Sacramento, CA',
      serviceArea: ['West Coast'],
      verified: true,
      services: ['Environmental Audits', 'Permit Management', 'Training', 'Risk Assessment'],
      contact: {
        phone: '(555) 567-8901',
        email: 'support@ecocompliance.com',
        website: 'www.ecocompliance.com'
      },
      image: 'https://tminurcnztragojlogjv.supabase.co/storage/v1/object/sign/Stock%20Photos/stock%20eco%20company%20pic.jfif?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJTdG9jayBQaG90b3Mvc3RvY2sgZWNvIGNvbXBhbnkgcGljLmpmaWYiLCJpYXQiOjE3MzE2Mzg2NzEsImV4cCI6MTc2MzE3NDY3MX0.sYgexFKwPT_nh83VhwkFt2Iy43afoPSqwReBw4gfZjs&t=2024-11-15T02%3A44%3A31.086Z',
      pricing: 'Standard',
      responseTime: '24-48 hours',
      certifications: ['EPA Certified', 'HAZWOPER']
    },
    {
      id: '8',
      name: 'Fuel Industry Legal Group',
      category: 'compliance',
      description: 'Specialized legal services for petroleum industry compliance.',
      rating: 4.7,
      reviewCount: 156,
      location: 'Atlanta, GA',
      serviceArea: ['Southeast', 'Nationwide'],
      verified: true,
      services: ['Regulatory Compliance', 'Litigation', 'Due Diligence', 'License Applications'],
      contact: {
        phone: '(555) 678-9012',
        email: 'legal@fuelgroup.com',
        website: 'www.fuelgroup.com'
      },
      image: 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80',
      pricing: 'Premium',
      responseTime: '24 hours',
      certifications: ['State Bar Certified', 'SIGMA Member']
    },

    // Construction & Equipment
    {
      id: '9',
      name: 'ConstructPro Builders',
      category: 'construction',
      description: 'Specialized construction and renovation services for gas stations.',
      rating: 4.8,
      reviewCount: 134,
      location: 'Los Angeles, CA',
      serviceArea: ['Los Angeles', 'Ventura', 'Santa Barbara'],
      verified: true,
      services: ['New Construction', 'Renovation', 'Permits'],
      contact: {
        phone: '(555) 789-0123',
        email: 'projects@constructpro.com',
        website: 'www.constructpro.com'
      },
      image: 'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?auto=format&fit=crop&q=80',
      pricing: 'Premium',
      responseTime: '48 hours',
      certifications: ['LEED', 'ICC']
    },

    // Marketing Services
    {
      id: '10',
      name: 'BrandMasters Marketing',
      category: 'marketing',
      description: 'Full-service marketing agency specializing in gas station branding.',
      rating: 4.7,
      reviewCount: 87,
      location: 'San Diego, CA',
      serviceArea: ['San Diego', 'Orange County', 'Riverside'],
      verified: true,
      services: ['Brand Design', 'Signage', 'Digital Marketing'],
      contact: {
        phone: '(555) 890-1234',
        email: 'hello@brandmasters.com',
        website: 'www.brandmasters.com'
      },
      image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80',
      pricing: 'Custom',
      responseTime: '24 hours',
      certifications: ['Google Partner', 'Meta Partner']
    }
  ];

  const filteredVendors = vendors.filter((vendor) => {
    if (filters.category && vendor.category !== filters.category) return false;
    if (filters.search && !vendor.name.toLowerCase().includes(filters.search.toLowerCase())) return false;
    if (filters.rating > 0 && vendor.rating < filters.rating) return false;
    if (filters.verified && !vendor.verified) return false;
    if (filters.serviceArea && !vendor.serviceArea.includes(filters.serviceArea)) return false;
    if (filters.services.length > 0 && !filters.services.some(service => vendor.services.includes(service))) return false;
    return true;
  });

  const handleRequestQuote = (vendorId: string) => {
    showToast({
      type: 'success',
      message: 'Quote request sent successfully!'
    });
  };

  const handleToggleFavorite = (vendorId: string) => {
    setFavorites((prev) => {
      if (prev.includes(vendorId)) {
        return prev.filter(id => id !== vendorId);
      }
      return [...prev, vendorId];
    });
  };

  const handleToggleCompare = (vendorId: string) => {
    setSelectedVendors((prev) => {
      if (prev.includes(vendorId)) {
        return prev.filter(id => id !== vendorId);
      }
      if (prev.length >= 3) {
        showToast({
          type: 'error',
          message: 'You can compare up to 3 vendors at a time'
        });
        return prev;
      }
      return [...prev, vendorId];
    });
  };

  return (
    <>
      {selectedVendors.length > 0 && (
        <div className="sticky top-0 z-10 bg-white p-4 rounded-lg shadow-lg mb-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="font-semibold">Selected for Comparison</h3>
              <p className="text-sm text-gray-600">
                {selectedVendors.length}/3 vendors selected
              </p>
            </div>
            <div className="flex gap-4">
              <Button
                variant="outline"
                onClick={() => setSelectedVendors([])}
              >
                Clear All
              </Button>
              <Button
                onClick={() => setShowComparison(true)}
                disabled={selectedVendors.length < 2}
              >
                Compare Vendors
              </Button>
            </div>
          </div>
          <div className="flex gap-4">
            {selectedVendors.map((id) => {
              const vendor = vendors.find((v) => v.id === id);
              if (!vendor) return null;
              return (
                <div
                  key={id}
                  className="flex items-center gap-2 bg-gray-50 px-3 py-2 rounded-lg"
                >
                  <span className="text-sm font-medium">{vendor.name}</span>
                  <button
                    onClick={() => handleToggleCompare(id)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredVendors.map((vendor) => (
          <VendorCard
            key={vendor.id}
            vendor={vendor}
            onRequestQuote={handleRequestQuote}
            onToggleFavorite={handleToggleFavorite}
            isFavorite={favorites.includes(vendor.id)}
            onToggleCompare={handleToggleCompare}
            isSelected={selectedVendors.includes(vendor.id)}
          />
        ))}
      </div>

      {showComparison && (
        <VendorComparison
          vendors={vendors.filter((v) => selectedVendors.includes(v.id))}
          onClose={() => setShowComparison(false)}
        />
      )}
    </>
  );
}