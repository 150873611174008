import React from 'react';
import { Star, MapPin, Phone, Mail, Shield, ArrowRight, Scale } from 'lucide-react';
import { Vendor } from '../../pages/VendorList';

interface VendorCardProps {
  vendor: Vendor;
  onRequestQuote: (vendorId: string) => void;
  onToggleFavorite: (vendorId: string) => void;
  onToggleCompare: (vendorId: string) => void;
  isFavorite: boolean;
  isSelected: boolean;
}

export default function VendorCard({
  vendor,
  onRequestQuote,
  onToggleFavorite,
  onToggleCompare,
  isFavorite,
  isSelected
}: VendorCardProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <div className="relative">
        <img
          src={vendor.image}
          alt={vendor.name}
          className="w-full h-48 object-cover"
        />
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={() => onToggleFavorite(vendor.id)}
            className={`p-2 rounded-full ${
              isFavorite ? 'bg-blue-600 text-white' : 'bg-white text-gray-600'
            }`}
          >
            <Star className="w-5 h-5" />
          </button>
          <button
            onClick={() => onToggleCompare(vendor.id)}
            className={`p-2 rounded-full ${
              isSelected ? 'bg-blue-600 text-white' : 'bg-white text-gray-600'
            }`}
          >
            <Scale className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-xl font-semibold mb-1">{vendor.name}</h3>
            <div className="flex items-center gap-2">
              {vendor.verified && (
                <div className="flex items-center gap-1 text-blue-600">
                  <Shield className="w-4 h-4" />
                  <span className="text-sm">Verified</span>
                </div>
              )}
              <div className="flex items-center gap-1">
                <Star className="w-4 h-4 text-amber-500 fill-current" />
                <span className="text-sm">{vendor.rating}</span>
                <span className="text-sm text-gray-600">
                  ({vendor.reviewCount} reviews)
                </span>
              </div>
            </div>
          </div>
        </div>

        <p className="text-gray-600 mb-4 line-clamp-2">
          {vendor.description}
        </p>

        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-gray-600">
            <MapPin className="w-4 h-4" />
            <span className="text-sm">{vendor.location}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Phone className="w-4 h-4" />
            <span className="text-sm">{vendor.contact.phone}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Mail className="w-4 h-4" />
            <span className="text-sm">{vendor.contact.email}</span>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {vendor.services.map((service) => (
            <span
              key={service}
              className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
            >
              {service}
            </span>
          ))}
        </div>

        <button
          onClick={() => onRequestQuote(vendor.id)}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
        >
          Request Quote
          <ArrowRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}