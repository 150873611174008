import React from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData } from '../ValuationWizard';
import { Tooltip } from '../../ui/Tooltip';

interface OperationalMetricsProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
}

const fuelTypes = [
  'Regular Unleaded',
  'Premium Unleaded',
  'Diesel',
  'E85',
  'Racing Fuel',
  'Biodiesel',
  'CNG',
  'EV Charging'
];

export default function OperationalMetrics({ data, onUpdate }: OperationalMetricsProps) {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Total Underground Storage Tank Capacity (Gallons)
          </label>
          <Tooltip content="Total capacity of all underground storage tanks">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="text"
          value={data.tankCapacity ? data.tankCapacity.toLocaleString() : ''}
          onChange={(e) => {
            const value = e.target.value;
            const numericValue = parseInt(value.replace(/,/g, ''), 10);
            onUpdate({ tankCapacity: isNaN(numericValue) ? 0 : numericValue });
          }}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., 30,000"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Operating Hours
          </label>
          <Tooltip content="Select the typical operating hours of the station">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.operatingHours}
          onChange={(e) => onUpdate({ operatingHours: e.target.value as '24/7' | 'specific' | 'not-applicable' })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="24/7">24/7 Operation</option>
          <option value="specific">Specific Hours</option>
          <option value="not-applicable">Not Applicable</option>
        </select>

        {data.operatingHours === 'specific' && (
          <div className="mt-4">
            <div className="flex items-center gap-2 mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Specify Hours
              </label>
              <Tooltip content="Enter the typical operating hours (e.g., 6AM-10PM)">
                <HelpCircle className="w-4 h-4 text-gray-400" />
              </Tooltip>
            </div>
            <input
              type="text"
              value={data.specificHours || ''}
              onChange={(e) => onUpdate({ specificHours: e.target.value })}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="e.g., 6AM-10PM"
            />
          </div>
        )}
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Number of Fuel Pumps
          </label>
          <Tooltip content="Total number of fueling positions">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <input
          type="number"
          value={data.numberOfPumps || ''}
          onChange={(e) => onUpdate({ numberOfPumps: Number(e.target.value) })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="e.g., 8"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Fuel Types Available
          </label>
          <Tooltip content="Select all fuel types offered at the station">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="space-y-2">
          {fuelTypes.map((type) => (
            <label key={type} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={data.fuelTypes.includes(type)}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...data.fuelTypes, type]
                    : data.fuelTypes.filter(t => t !== type);
                  onUpdate({ fuelTypes: newTypes });
                }}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">{type}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}