import React from 'react';
import { Slider } from '../ui/Slider';
import { Checkbox } from '../ui/Checkbox';
import { VendorCategory } from '../../pages/VendorList';

interface VendorFiltersProps {
  filters: {
    category: VendorCategory | '';
    search: string;
    rating: number;
    verified: boolean;
    serviceArea: string;
    services: string[];
  };
  onChange: (filters: any) => void;
}

export default function VendorFilters({ filters, onChange }: VendorFiltersProps) {
  const serviceAreas = ['Los Angeles', 'San Francisco', 'Sacramento', 'San Diego'];
  const services = [
    'Fuel Delivery',
    'Pump Repair',
    'Tank Maintenance',
    'Environmental Compliance',
    'POS Systems',
    'Emergency Service'
  ];

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Minimum Rating
        </label>
        <Slider
          min={0}
          max={5}
          step={0.5}
          value={[filters.rating]}
          onChange={([value]) => onChange({ ...filters, rating: value })}
          formatLabel={(value) => `${value} stars`}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Service Area
        </label>
        <select
          value={filters.serviceArea}
          onChange={(e) => onChange({ ...filters, serviceArea: e.target.value })}
          className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All Areas</option>
          {serviceAreas.map((area) => (
            <option key={area} value={area}>
              {area}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Services
        </label>
        <div className="space-y-2">
          {services.map((service) => (
            <Checkbox
              key={service}
              label={service}
              checked={filters.services.includes(service)}
              onChange={(checked) => {
                const newServices = checked
                  ? [...filters.services, service]
                  : filters.services.filter((s) => s !== service);
                onChange({ ...filters, services: newServices });
              }}
            />
          ))}
        </div>
      </div>

      <div>
        <Checkbox
          label="Verified Vendors Only"
          checked={filters.verified}
          onChange={(checked) => onChange({ ...filters, verified: checked })}
        />
      </div>
    </div>
  );
}