import React, { useState } from 'react';
import { BarChart2, Calendar, Download, Share2 } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { Select } from '../components/ui/Select';
import { Card } from '../components/ui/Card';

// Analytics Components
import MetricsOverview from '../components/analytics/MetricsOverview';
import MarketTrends from '../components/analytics/MarketTrends';
import CompetitorMap from '../components/analytics/CompetitorMap';
import RevenueBreakdown from '../components/analytics/RevenueBreakdown';

export default function Analytics() {
  const [dateRange, setDateRange] = useState('30d');
  const [region, setRegion] = useState('all');

  const dateRanges = [
    { value: '7d', label: 'Last 7 Days' },
    { value: '30d', label: 'Last 30 Days' },
    { value: '90d', label: 'Last 90 Days' },
    { value: '1y', label: 'Last Year' }
  ];

  const regions = [
    { value: 'all', label: 'All Regions' },
    { value: 'west', label: 'West Coast' },
    { value: 'east', label: 'East Coast' },
    { value: 'central', label: 'Central' }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-8">
        <div className="flex items-center gap-3">
          <BarChart2 className="w-8 h-8 text-blue-600" />
          <div>
            <h1 className="text-3xl font-bold">Analytics Dashboard</h1>
            <p className="text-gray-600">Track performance metrics and market trends</p>
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <Select
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              options={dateRanges}
              placeholder="Select Date Range"
            />
          </div>
          <Select
            value={region}
            onChange={(value) => setRegion(value)}
            options={regions}
            placeholder="Select Region"
          />
          <Button variant="outline" className="flex items-center gap-2">
            <Download className="w-4 h-4" />
            Export
          </Button>
          <Button variant="outline" className="flex items-center gap-2">
            <Share2 className="w-4 h-4" />
            Share
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <MetricsOverview />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <MarketTrends />
        <RevenueBreakdown />
      </div>

      <div className="mb-8">
        <CompetitorMap />
      </div>
    </div>
  );
}