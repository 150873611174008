import React from 'react';
import { TrendingUp, TrendingDown, DollarSign, Fuel, Users, Building2 } from 'lucide-react';
import { Card } from '../ui/Card';

interface Metric {
  label: string;
  value: string | number;
  change: number;
  icon: React.ReactNode;
}

export default function MetricsOverview() {
  const metrics: Metric[] = [
    {
      label: 'Average Property Value',
      value: '$2.5M',
      change: 12.5,
      icon: <DollarSign className="w-6 h-6" />
    },
    {
      label: 'Monthly Fuel Volume',
      value: '150K gal',
      change: 8.3,
      icon: <Fuel className="w-6 h-6" />
    },
    {
      label: 'Active Listings',
      value: 48,
      change: -5.2,
      icon: <Building2 className="w-6 h-6" />
    },
    {
      label: 'Market Reach',
      value: '25K',
      change: 15.8,
      icon: <Users className="w-6 h-6" />
    }
  ];

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {metrics.map((metric) => (
        <Card key={metric.label}>
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-50 text-blue-600 rounded-lg">
              {metric.icon}
            </div>
            <span className="text-sm text-gray-600">{metric.label}</span>
          </div>
          
          <div className="flex items-end justify-between">
            <span className="text-2xl font-bold">{metric.value}</span>
            <div className={`flex items-center gap-1 text-sm ${
              metric.change >= 0 ? 'text-green-600' : 'text-red-600'
            }`}>
              {metric.change >= 0 ? (
                <TrendingUp className="w-4 h-4" />
              ) : (
                <TrendingDown className="w-4 h-4" />
              )}
              {Math.abs(metric.change)}%
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}