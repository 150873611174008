import React, { useState } from 'react';
import { HelpCircle } from 'lucide-react';
import { ValuationData } from '../ValuationWizard';
import { Tooltip } from '../../ui/Tooltip';

interface ProfitCentersProps {
  data: ValuationData;
  onUpdate: (data: Partial<ValuationData>) => void;
}

const foodServiceTypes = [
  'Quick Service Restaurant',
  'Café',
  'Deli',
  'Other'
];

const additionalServices = [
  'Auto Repair',
  'Oil Change',
  'Tire Service',
  'EV Charging',
  'Truck Parking',
  'ATM',
  'Car Wash',
  'Vacuum Services',
  'Propane Exchange',
  'Storage Units'
];

export default function ProfitCenters({ data, onUpdate }: ProfitCentersProps) {
  const [isAnnual, setIsAnnual] = useState(false);

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isAnnualSelected = e.target.value === 'annual';
    setIsAnnual(isAnnualSelected);
    
    // Convert existing values when switching periods
    if (isAnnualSelected) {
      onUpdate({
        foodServiceRevenue: (data.foodServiceRevenue || 0) * 12,
        foodServiceRent: (data.foodServiceRent || 0) * 12
      });
    } else {
      onUpdate({
        foodServiceRevenue: Math.round((data.foodServiceRevenue || 0) / 12),
        foodServiceRent: Math.round((data.foodServiceRent || 0) / 12)
      });
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex items-center gap-2 mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Reporting Period
          </label>
          <select
            value={isAnnual ? 'annual' : 'monthly'}
            onChange={handlePeriodChange}
            className="ml-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="monthly">Monthly</option>
            <option value="annual">Annual</option>
          </select>
        </div>
      </div>

      {/* Car Wash Section */}
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Car Wash Available
          </label>
          <Tooltip content="Indicate if the property includes a car wash facility">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.hasCarWash ? 'yes' : 'no'}
          onChange={(e) => onUpdate({ hasCarWash: e.target.value === 'yes' })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>

        {data.hasCarWash && (
          <div className="space-y-4 mt-4">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Car Wash Type
                </label>
                <Tooltip content="Select the type of car wash facility">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <select
                value={data.carWashType || ''}
                onChange={(e) => onUpdate({ carWashType: e.target.value as 'automatic' | 'self-service' })}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="automatic">Automatic</option>
                <option value="self-service">Self-Service</option>
              </select>
            </div>

            <div>
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  {isAnnual ? 'Annual' : 'Monthly'} Car Wash Revenue
                </label>
                <Tooltip content={`Average ${isAnnual ? 'annual' : 'monthly'} revenue from car wash operations`}>
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <div className="relative">
                <span className="absolute left-3 top-3 text-gray-500">$</span>
                <input
                  type="text"
                  value={data.carWashRevenue ? data.carWashRevenue.toLocaleString() : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = parseInt(value.replace(/,/g, ''), 10);
                    onUpdate({ carWashRevenue: isNaN(numericValue) ? 0 : numericValue });
                  }}
                  className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={isAnnual ? "e.g., 120,000" : "e.g., 10,000"}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Food Service Section */}
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Food Service Available
          </label>
          <Tooltip content="Indicate if the property includes food service operations">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <select
          value={data.hasFoodService ? 'yes' : 'no'}
          onChange={(e) => onUpdate({ hasFoodService: e.target.value === 'yes' })}
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>

        {data.hasFoodService && (
          <div className="space-y-4 mt-4">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Food Service Ownership
                </label>
                <Tooltip content="Select whether the food service operation is owned or leased">
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <select
                value={data.foodServiceOwnership || 'owned'}
                onChange={(e) => onUpdate({ foodServiceOwnership: e.target.value as 'owned' | 'leased' })}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="owned">Owned</option>
                <option value="leased">Leased</option>
              </select>
            </div>

            {data.foodServiceOwnership === 'leased' && (
              <>
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Lease Term Years Remaining
                    </label>
                    <Tooltip content="Enter the number of years remaining on the food service lease">
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                    </Tooltip>
                  </div>
                  <input
                    type="text"
                    value={data.foodServiceLeaseYears ? data.foodServiceLeaseYears.toLocaleString() : ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      const numericValue = parseInt(value.replace(/,/g, ''), 10);
                      onUpdate({ foodServiceLeaseYears: isNaN(numericValue) ? 0 : numericValue });
                    }}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Years remaining"
                  />
                </div>

                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      {isAnnual ? 'Annual' : 'Monthly'} Rent
                    </label>
                    <Tooltip content={`${isAnnual ? 'Annual' : 'Monthly'} rent for the food service operation`}>
                      <HelpCircle className="w-4 h-4 text-gray-400" />
                    </Tooltip>
                  </div>
                  <div className="relative">
                    <span className="absolute left-3 top-3 text-gray-500">$</span>
                    <input
                      type="text"
                      value={data.foodServiceRent ? data.foodServiceRent.toLocaleString() : ''}
                      onChange={(e) => {
                        const value = e.target.value;
                        const numericValue = parseInt(value.replace(/,/g, ''), 10);
                        onUpdate({ foodServiceRent: isNaN(numericValue) ? 0 : numericValue });
                      }}
                      className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={isAnnual ? "e.g., 60,000" : "e.g., 5,000"}
                    />
                  </div>
                </div>
              </>
            )}

            {data.foodServiceOwnership === 'owned' && (
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Food Service Types
                  </label>
                  <Tooltip content="Select all types of food service offered">
                    <HelpCircle className="w-4 h-4 text-gray-400" />
                  </Tooltip>
                </div>
                <div className="space-y-2">
                  {foodServiceTypes.map((type) => (
                    <label key={type} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={(data.foodServiceType || []).includes(type)}
                        onChange={(e) => {
                          const newTypes = e.target.checked
                            ? [...(data.foodServiceType || []), type]
                            : (data.foodServiceType || []).filter(t => t !== type);
                          onUpdate({ foodServiceType: newTypes });
                        }}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-sm text-gray-700">{type}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <div>
              <div className="flex items-center gap-2 mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  {isAnnual ? 'Annual' : 'Monthly'} Food Service Revenue
                </label>
                <Tooltip content={`Average ${isAnnual ? 'annual' : 'monthly'} revenue from food service operations`}>
                  <HelpCircle className="w-4 h-4 text-gray-400" />
                </Tooltip>
              </div>
              <div className="relative">
                <span className="absolute left-3 top-3 text-gray-500">$</span>
                <input
                  type="text"
                  value={data.foodServiceRevenue ? data.foodServiceRevenue.toLocaleString() : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = parseInt(value.replace(/,/g, ''), 10);
                    onUpdate({ foodServiceRevenue: isNaN(numericValue) ? 0 : numericValue });
                  }}
                  className="w-full p-3 pl-7 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={isAnnual ? "e.g., 240,000" : "e.g., 20,000"}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Additional Services Section */}
      <div>
        <div className="flex items-center gap-2 mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Additional Services
          </label>
          <Tooltip content="Select all additional services offered at the property">
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </Tooltip>
        </div>
        <div className="space-y-2">
          {additionalServices.map((service) => (
            <label key={service} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={data.additionalServices.includes(service)}
                onChange={(e) => {
                  const newServices = e.target.checked
                    ? [...data.additionalServices, service]
                    : data.additionalServices.filter(s => s !== service);
                  onUpdate({ additionalServices: newServices });
                }}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="text-sm text-gray-700">{service}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}