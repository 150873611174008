import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Please set up your Supabase environment variables in .env file. Check .env.example for required variables.');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

export const signInWithEmail = async (email: string, password: string) => {
  const response = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (response.error) throw response.error;
  return response;
};

export const signUpWithEmail = async (email: string, password: string, metadata?: { [key: string]: any }) => {
  const response = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: metadata,
    },
  });

  if (response.error) throw response.error;
  return response;
};

export const signInWithGoogle = async () => {
  const response = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
      },
      redirectTo: `${window.location.origin}/auth/callback`
    }
  });

  if (response.error) throw response.error;
  return response;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};